
import { number_to_currency, format_date_less_one, as_json } from '@/lib/helpers'
import LoadingSpinner from '@/components/loading_spinner'

export default {
  name: 'CategoryBanners'
  components: { LoadingSpinner }
  props: {
    offers: { type: Array }
  }

  methods: {
    number_to_currency: (cents) ->
      number_to_currency({ symbol: @$store.getters['application/currency'], cents: cents })

    format_date_less_one: (date) -> format_date_less_one(date)

    show_add_to_cart_modal: (offer) ->
      @$store.commit('offers/set_current', offer)
  }

  computed: {
    carousel_style: ->
      style = @$store.getters['application/promotion_carousel']
      if style in ['striped'] then "r---carousel-#{style}" else ''
  }
}
